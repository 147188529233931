import { supabase } from '../../../services/supabaseClient';

const getProcessWebhookReference = async (orderReference: string) => {
    const cleanedOrderReference = orderReference.replace(/#/g, '');

    const { data, error } = await supabase
        .from('processed_webhooks')
        .select('webhook_id')
        .eq('order_reference', orderReference)
        .single();

    if (error) {
        throw new Error('Erreur lors de la récupération de la référence de commande: ' + error.message);
    }

    if (!data) {
        throw new Error('Aucune donnée trouvée pour la référence de commande fournie.');
    }

    const resultString = `${cleanedOrderReference}/${data.webhook_id}`;

    return resultString;
};


export const generateHtmlContent = async (jsonResult: any[], isPremium: boolean, orderReference: string) => {

    if (isPremium) {
        const processWebhookReference = await getProcessWebhookReference(orderReference);
        const urlMailKit = `https://in-ze-pocket.com/order/custom-card/${processWebhookReference}`;
        return `
            <div style="font-family: Arial, sans-serif; line-height: 1.6; margin: 20px; padding: 20px; border: 1px solid #ddd; border-radius: 8px; background-color: #f9f9f9;">
                <h2 style="color: black; font-weight: bold;">Bonjour,</h2>
                <p style="color: black;">Merci pour votre commande de carte PREMIUM ! 🙌</p>
                <p style="color: black;">Avant que votre carte ne parte en production, il reste une dernière étape : la personnalisation.</p>
                <p style="color: black;">Cliquez simplement sur le lien ci-dessous pour finaliser et personnaliser votre carte à votre image :</p>
                <p style="color: black;">👉 <a href="${urlMailKit}" style="color: #2196F3; text-decoration: none;">[Lien personnalisé]</a></p>
                <p style="color: black;">🚀 Une fois votre personnalisation effectuée, votre carte sera lancée en production pour être livrée dans les plus brefs délais.</p>
                <p style="color: black;">À très bientôt,<br>L'équipe In Ze Pocket</p>
            </div>
        `;
    } else {
        return `
            <div style="font-family: Arial, sans-serif; line-height: 1.6; margin: 20px; padding: 20px; border: 1px solid #ddd; border-radius: 8px; background-color: #f9f9f9;">
                <h2 style="color: black; font-weight: bold;">Bonjour,</h2>
                <p style="color: black;">Merci beaucoup pour votre commande et bienvenue dans l’univers In Ze Pocket ! 🎉</p>
                <p style="color: black;">Vous trouverez ci-dessous les informations importantes pour utiliser vos produits :</p>
                <p style="color: black;"><strong>Identifiant de la carte :</strong> Cet identifiant est unique à votre carte.</p>
                <p style="color: black;"><strong>Code de la carte :</strong> Utilisez ce code pour lier votre carte à votre profil numérique via notre application mobile.</p>
                <p style="color: black;">Pas d'inquiétude, le processus est simple et rapide ! 😎</p>
                <p style="color: black;">Pour commencer, téléchargez notre application mobile sur l'App Store ou Google Play.</p>
                <p style="color: black;">👉 <a href="https://urlgeni.us/NIW6F" style="color: #2196F3; text-decoration: none;">[Lien vers notre application mobile]</a></p>
                <p style="color: black;">Une fois l'application installée, suivez les instructions pour ajouter votre carte à votre profil numérique.</p>
                <p style="color: black;">Vous pouvez également le faire manuellement en allant dans les paramètres puis en sélectionnant l'option "Lier ma carte".</p>
                <p style="color: red;"><strong>⚠️ Important :</strong> Veuillez ne pas partager ces informations avec quiconque.</p>

                <h3 style="color: #2196F3;">Détails de votre commande :</h3>
                <ul style="list-style-type: none; padding: 0;">
                    ${jsonResult.map((card, index) => `
                        <li style="margin-bottom: 10px; padding: 10px; border: 1px solid #ddd; border-radius: 4px; background-color: #fff; color: black;">
                            ${index + 1}. <strong>${card.cardType}</strong> : Identifiant de la carte : <strong>${card.cardId}</strong> Code de la carte : <strong>${card.token}</strong>
                        </li>
                    `).join('')}
                </ul>

                <p style="color: black;">Si vous avez la moindre question, notre équipe est là pour vous aider.</p>
                <p style="color: black;">À très bientôt,<br>L'équipe In Ze Pocket</p>
            </div>
        `;
    }
};
