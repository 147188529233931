import React from "react";
import "../styles/components/Description.css";

interface DescriptionProps {
    description: string | null | undefined;
}

const Description: React.FC<DescriptionProps> = ({ description }) => {
    return (
        <div className="description-text">
            {description}
        </div>
    );
};

export default Description;
