import React, { useState, useEffect } from 'react';
import '../../styles/components/custom_card_order/NameInputForm.css';

const MAX_LENGTH = 30;

interface CustomCard {
    firstName: string;
    lastName: string;
    color: string;
    customColor: string;
    imageUrl: string;
}

interface NameInputFormProps {
    customCard: CustomCard;
    onUpdate: (updatedCard: CustomCard) => void;
}

const NameInputForm: React.FC<NameInputFormProps> = ({ customCard, onUpdate }) => {
    const [firstName, setFirstName] = useState<string>(customCard.firstName);
    const [lastName, setLastName] = useState<string>(customCard.lastName);

    useEffect(() => {
        setFirstName(customCard.firstName);
        setLastName(customCard.lastName);
    }, [customCard]);

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFirstName(value);
        if (value.length <= MAX_LENGTH) {
            onUpdate({ ...customCard, firstName: value, lastName });
        }
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLastName(value);
        if (value.length <= MAX_LENGTH) {
            onUpdate({ ...customCard, firstName, lastName: value });
        }
    };

    return (
        <div className="name-input-form">
            <div className="row-name">
                <div className="input-group">
                    <label htmlFor="first-name">Prénom sur la carte :</label>
                    <input
                        type="text"
                        id="first-name"
                        value={firstName}
                        className="custom-card-input"
                        onChange={handleFirstNameChange}
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="last-name">Nom sur la carte :</label>
                    <input
                        type="text"
                        id="last-name"
                        value={lastName}
                        className="custom-card-input"
                        onChange={handleLastNameChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default NameInputForm;
