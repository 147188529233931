import React from 'react';
import '../../styles/components/received_contact/SubmitButtonForm.css'

interface SubmitButtonProps {
    onClick: () => void;
    isDisabled: boolean;
}

const SubmitButtonForm: React.FC<SubmitButtonProps> = ({ onClick, isDisabled }) => {
    return (
        <div className="submit-button-container">
            <button
                type="button"
                className="submit-button"
                onClick={onClick}
                disabled={isDisabled}
            >
                Envoyer
            </button>
        </div>
    );
};

export default SubmitButtonForm;
