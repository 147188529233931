import { fetchCardsByOrderReference } from '../hooks/fetchCardsByOrderReference';
import { sendEmail } from './sendEmail';
import { cardTypes } from '../types/cardOrders/cardTypes';
import { generateHtmlContent } from '../components/dashboard/cardOrderDashboard/generateHtmlContent';

export const processEmailForCards = async (type: string, updateStatus: string, orderReference: string, email: string) => {
    const jsonResult = await fetchCardsByOrderReference(orderReference);
    const subjectContent = type === cardTypes.PREMIUM
        ? `Personnalisation de votre carte ! Votre Mailkit ${orderReference} est prêt`
        : `Votre commande In Ze Pocket ${orderReference} est prête – Bienvenue dans notre écosystème !`;

    const htmlContent = await generateHtmlContent(jsonResult, type === cardTypes.PREMIUM, orderReference);
    await sendEmail(email, subjectContent, htmlContent, updateStatus, orderReference);
};
