import { supabase } from "./supabaseClient";
import { cardTypes } from "../types/cardOrders/cardTypes";
import { StatusCardOrders } from "../types/cardOrders/statusCardOrders";
import { ListCards } from "../types/cardOrders/listCards";

export const getCustomCardOrder = async (orderReference: string, token: string) => {
    const { data, error } = await supabase
        .from('processed_webhooks')
        .select('*')
        .eq('order_reference', orderReference)
        .single();

    if (error) {
        if (error.code === 'PGRST116') {
            throw new Error("Aucune commande trouvée pour cette référence.");
        }
        throw new Error(error.message);
    }

    if (data.webhook_id !== token) {
        throw new Error("Accès refusé : Veuillez vérifier votre lien ou contacter le support client si le problème persiste.");
    }

    const { data: cardOrdersData, error: cardOrdersError } = await supabase
        .from('card_orders')
        .select('*')
        .eq('order_reference', orderReference)
        .eq('card_type', cardTypes.PREMIUM);

    if (cardOrdersError) {
        throw new Error(cardOrdersError.message);
    }

    if (cardOrdersData.length === 0) {
        throw new Error(
            "Aucune interaction n'est requise pour cette commande."
        );
    }

    const erroredOrder = cardOrdersData.find((card: any) =>
        card.order_status === StatusCardOrders.MAIL_KIT_ERROR
    );

    if (erroredOrder) {
        throw new Error("Les images soumises n'ont pas répondu à nos critères de qualité. Vous recevrez un email contenant les instructions nécessaires pour les modifications requises.");
    }

    const sentOrder = cardOrdersData.find((card: any) =>
        card.order_status === StatusCardOrders.MAIL_KIT_SENT
    );

    let orderStatus: StatusCardOrders;

    if (sentOrder) {
        orderStatus = sentOrder.order_status;
    } else {
        orderStatus = cardOrdersData[0].order_status;
    }

    switch (orderStatus) {
        case StatusCardOrders.CREATED:
            throw new Error("Votre commande a été créée, mais son traitement n'a pas encore commencé.");
        case StatusCardOrders.MAIL_KIT_SENT:
            break;
        case StatusCardOrders.MAIL_KIT_SUCCES:
            throw new Error("Votre commande a été vérifiée et acceptée, vous recevrez un email pour la suite du processus.");
        case StatusCardOrders.MAIL_KIT_ERROR:
            throw new Error("Les images soumises n'ont pas répondu à nos critères de qualité. Vous recevrez un email contenant les instructions nécessaires pour les modifications requises.");
        case StatusCardOrders.MAIL_KIT_PENDING:
            throw new Error("Vos informations ont bien été prises en compte, votre commande est en attente de traitement.");
        case StatusCardOrders.FINISH:
            throw new Error("Votre commande est en cours de production.");
        default:
            throw new Error("Statut de la commande inconnu. Veuillez contacter le support.");
    }

    const cardOrdersList: ListCards[] = cardOrdersData
    .filter((card: any) => card.order_status === StatusCardOrders.MAIL_KIT_SENT)
    .map((card: any) => ({
        card_id: card.card_id,
        order_reference: card.order_reference,
        card_type: card.card_type,
        order_status: card.order_status,
        customer_email: card.customer_email,
        customer_first_name: card.customer_first_name,
        customer_last_name: card.customer_last_name,
        customer_phone: card.customer_phone,
        card_picture: card.card_picture || null,
        card_color: card.card_color || null,
        order_date: card.order_date || null
    }));

    return cardOrdersList;
};
