import React, { useEffect, useRef, useState } from 'react';

import '../../styles/components/custom_card_order/ImageUpload.css';

interface ImageUploaderProps {
    onImageUpload: (imageUrl: string, fileName: string) => void;
    currentImage: string | null;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageUpload, currentImage }) => {
    const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(currentImage || null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setImagePreview(currentImage || null);
    }, [currentImage]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                onImageUpload(URL.createObjectURL(file), file.name);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="image-uploader">
            <label className="upload-button">
                Ajouter votre logo pour la carte
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className='form-file-input'
                    ref={fileInputRef}
                />
            </label>
            {imagePreview && (
                <img
                    src={imagePreview as string}
                    alt="Aperçu"
                    className="upload-preview"
                />
            )}
        </div>
    );
};

export default ImageUploader;
