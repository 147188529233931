import React from 'react';
import logo from '../../assets/images/logo_name.png';

import '../../styles/components/custom_card_order/OrderHeader.css';


interface OrderHeaderProps {
    orderId: string;
}

const OrderHeader: React.FC<OrderHeaderProps> = ({ orderId }) => {

    const formattedOrderId = orderId.substring(1);

    return (
        <div className='order-header-container'>
            <img src={logo} className="logo-order" alt="logo" />
            <h1 className='order-reference-title'>Référence de la commande : {formattedOrderId}</h1>
        </div>
    );
};

export default OrderHeader;
