import React from 'react';

import '../../styles/components/custom_card_order/NavigationButtons.css';

interface NavigationButtonsProps {
    currentIndex: number;
    totalCards: number;
    onNavigate: (direction: 'next' | 'prev') => void;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({ currentIndex, totalCards, onNavigate }) => {
    return (
        <div className="navigation-buttons">
            <button
                disabled={currentIndex === 0}
                onClick={() => onNavigate('prev')}
            >
                Précédent
            </button>
            <button
                disabled={currentIndex === (totalCards - 1)}
                onClick={() => onNavigate('next')}
            >
                Suivant
            </button>
        </div>
    );
};

export default NavigationButtons;
