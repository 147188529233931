import React from 'react';
import '../styles/components/LoadingScreen.css';
import logo from '../assets/images/dark_logo.png';

const LoadingScreen: React.FC = () => {
    return (
        <div className="loading-screen">
            <img src={logo} alt="Loading" className="loading-logo" />
        </div>
    );
};

export default LoadingScreen;
