import React from 'react';
import { ListCards } from '../../../types/cardOrders/listCards';
import { FaUserCircle, FaTimes } from 'react-icons/fa';
import OrderDetails from './OrderDetails';

interface OrderCardProps {
    orderReference: string;
    orders: ListCards[];
    expandedOrder: string | null;
    showCustomerInfo: string | null;
    handleToggle: (orderReference: string) => void;
    setShowCustomerInfo: React.Dispatch<React.SetStateAction<string | null>>;
}

const OrderCard: React.FC<OrderCardProps> = ({ 
    orderReference, 
    orders, 
    expandedOrder, 
    showCustomerInfo, 
    handleToggle, 
    setShowCustomerInfo, 
}) => {
    const isExpanded = expandedOrder === orderReference;
    const isCustomerInfoVisible = showCustomerInfo === orderReference;

    return (
        <div className={`card ${isExpanded ? 'no-hover' : ''}`}>
            <div className="card-header">
                <h2>{orderReference} (Total: {orders.length})</h2>
                <h4>{new Date(orders[0].order_date || '').toLocaleDateString('fr-FR')}</h4>
                <div className="customer-avatar" onClick={() => setShowCustomerInfo(isCustomerInfoVisible ? null : orderReference)}>
                    {isCustomerInfoVisible ? <FaTimes size={30} /> : <FaUserCircle size={30} />}
                </div>
            </div>
            {isCustomerInfoVisible && (
                <div className="customer-info">
                    <h4>Email: {orders[0].customer_email}</h4>
                    <h4>Téléphone: {orders[0].customer_phone}</h4>
                </div>
            )}
            <div className="toggle-details">
                <span onClick={() => handleToggle(orderReference)}>
                    {isExpanded ? 'Voir moins de détails' : 'Voir les détails'}
                </span>
            </div>
            {isExpanded && <OrderDetails orders={orders} />}
        </div>
    );
};

export default OrderCard;
