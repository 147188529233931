import React, { useState, useEffect } from 'react';
import { supabase } from '../../services/supabaseClient';
import Login from '../../components/auth/Login';
import DashboardContent from './DashboardContent';
import { useFetchUser } from '../../hooks/useFetchUser';
import { useFetchCardOrders } from '../../hooks/useFetchCardOrders';

const VALID_USER_ID = "047cfdad-59e4-444d-a281-f7bb9c1da750";

const Dashboard: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const { user, loading: loadingUser, error: errorUser } = useFetchUser(userId);
  const { listCards, loadingCards, errorCards, fetchCardOrders } = useFetchCardOrders();

  const handleLogin = async (inputUserId: string): Promise<void> => {
    if (inputUserId === VALID_USER_ID) {
      setIsAuthenticated(true);
      setUserId(inputUserId);
    } else {
      handleLogout();
      alert('Invalid user ID provided.');
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsAuthenticated(false);
    setUserId(null);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const subscription = supabase
        .channel('public:card_orders')
        .on('postgres_changes', { event: '*', schema: 'public', table: 'card_orders' }, () => {
          fetchCardOrders();
          setIsUpdated(true);
        })
        .subscribe();

      return () => {
        supabase.removeChannel(subscription);
      };
    }
  }, [isAuthenticated, fetchCardOrders]);

  useEffect(() => {
    if (isUpdated) {
      alert("Les commandes de cartes ont été actualisées.");
      setIsUpdated(false);
    }
  }, [isUpdated]);

  if (!isAuthenticated) {
    return (
      <div>
        <Login onLogin={handleLogin} />
      </div>
    );
  }

  return (
    <div>
      <DashboardContent
        user={user}
        loadingUser={loadingUser}
        errorUser={errorUser}
        listCards={listCards}
        loadingCards={loadingCards}
        errorCards={errorCards}
        fetchCardOrders={fetchCardOrders}
        handleLogout={handleLogout}
      />
      {listCards && listCards.length === 0 && <p>Aucune carte disponible.</p>}
      {loadingCards && <p>Chargement des cartes...</p>}
      {errorCards && <p>Erreur lors du chargement des cartes.</p>}
    </div>
  );
};

export default Dashboard;
