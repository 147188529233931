import React from 'react';
import '../../styles/components/received_contact/NameInputsForm.css';

interface NameInputsFormProps {
    firstName: string;
    setFirstName: (value: string) => void;
    lastName: string;
    setLastName: (value: string) => void;
    errors: {
        firstName: string;
        lastName: string;
    };
}

const NameInputsForm: React.FC<NameInputsFormProps> = ({ firstName, setFirstName, lastName, setLastName, errors }) => {
    return (
        <div className="name-inputs">
            <div className="name-input-group">
                <label htmlFor="first-name" className="name-input-label">
                    Prénom <span className="required">*</span>
                </label>
                <input
                    type="text"
                    id="first-name"
                    name="firstName"
                    placeholder="Prénom"
                    value={firstName}
                    className="name-input-field"
                    onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && <span className="error-message-form">{errors.firstName}</span>}
            </div>
            <div className="name-input-group">
                <label htmlFor="last-name" className="name-input-label">
                    Nom <span className="required">*</span>
                </label>
                <input
                    type="text"
                    id="last-name"
                    name="lastName"
                    placeholder="Nom"
                    className="name-input-field"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && <span className="error-message-form">{errors.lastName}</span>}
            </div>
        </div>
    );
};

export default NameInputsForm;
