import React from "react";
import "../styles/components/Name.css";

interface NameProps {
    firstName: string | null | undefined;
    lastName: string | null | undefined;
}

const Name: React.FC<NameProps> = ({ firstName, lastName }) => {
    return (
        <div className="name-row">
            <span>{firstName}</span>
            <span>{lastName}</span>
        </div>
    );
};

export default Name;
