import { useState, useEffect } from 'react';
import { supabase } from '../services/supabaseClient';
import { ListCards } from '../types/cardOrders/listCards';

export const useFetchCardOrders = () => {
  const [listCards, setListCards] = useState<ListCards[] | null>(null);
  const [loadingCards, setLoadingCards] = useState<boolean>(true);
  const [errorCards, setErrorCards] = useState<string | null>(null);

  const fetchCardOrders = async () => {
    try {
      setLoadingCards(true);
      const { data, error } = await supabase
        .from('card_orders')
        .select('*');

      if (error) throw new Error(error.message);

      setListCards(data);
    } catch (error) {
      if (error instanceof Error) {
        setErrorCards(`Erreur: ${error.message}`);
      } else {
        setErrorCards('Impossible de récupérer les commandes de cartes.');
      }
    } finally {
      setLoadingCards(false);
    }
  };

  useEffect(() => {
    fetchCardOrders();
  }, []);

  return { listCards, loadingCards, errorCards, fetchCardOrders };
};
