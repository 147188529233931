import React from "react";
import '../../styles/components/received_contact/FormOverlay.css';

interface FormOverlayProps {
    isVisible: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const FormOverlay: React.FC<FormOverlayProps> = ({ isVisible, onClose, children }) => {
    if (!isVisible) return null;

    return (
        <div className="form-overlay" onClick={onClose}>
            <div className="floating-container" onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default FormOverlay;
