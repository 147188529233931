import { supabase } from '../../../services/supabaseClient';

export const deleteCardsOrder = async (orderReference: string) => {
    try {
        if (!orderReference) {
            alert('Référence de commande non valide');
            return;
        }
        const { error } = await supabase
            .from('card_orders')
            .delete()
            .eq('order_reference', orderReference);
        if (error) {
            console.error(`Erreur lors de la suppression des commandes : ${error.message}`);
        }
    } catch (error) {
        console.error('Erreur lors de la suppression des commandes:', error);
    }
};
