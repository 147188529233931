import React from "react";
import '../styles/components/BannerImage.css';

interface BannerImageProps {
    bannerUrl: string | null | undefined;
    profileUrl: string | null | undefined;
}

const BannerImage: React.FC<BannerImageProps> = ({ bannerUrl, profileUrl }) => {
    const defaultBannerUrl = "https://i.pinimg.com/564x/ae/bf/ac/aebfacfef71ac27cd9a80bca17a8a73e.jpg";
    const defaultProfileUrl = "https://i.pinimg.com/564x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg";

    return (
        <div className="banner-container">
            <img src={bannerUrl || defaultBannerUrl} alt="Banner" className="banner-image" />
            <img src={profileUrl || defaultProfileUrl} alt="Profile" className="profile-image" />
        </div>
    );
};

export default BannerImage;