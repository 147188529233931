import React from 'react';
import '../../styles/components/received_contact/MessageInputForm.css';

interface MessageInputFormProps {
    message: string;
    setMessage: (value: string) => void;
    error: string;
}

const MessageInputForm: React.FC<MessageInputFormProps> = ({ message, setMessage, error }) => {
    return (
        <div className="message-input-form">
            <label htmlFor="message" className="message-input-label">
                Message
            </label>
            <textarea
                id="message"
                name="message"
                placeholder="Votre message..."
                className="message-input-field"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={3}
            />
            {error && <span className="error-message-form">{error}</span>}
        </div>
    );
};

export default MessageInputForm;
