import React, { useEffect, useState } from 'react';
import '../styles/pages/Home.css';
import primary_image from '../assets/images/background_izp.png';
import googlePlayBadge from '../assets/images/google_play.png';
import appleStoreBadge from '../assets/images/apple_store.svg';
import companyLogo from '../assets/images/logo_white.png';
import LoadingScreen from '../components/LoadingScreen';


const Home: React.FC = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const images = [primary_image, googlePlayBadge, appleStoreBadge, companyLogo];

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        const loadImages = async () => {
            const promises = images.map((src) => {
                return new Promise<void>((resolve) => {
                    const img = new Image();
                    img.src = src;
                    img.onload = () => resolve();
                });
            });

            await Promise.all(promises);
            setIsLoading(false);
        };

        loadImages();
    }, [images]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className='home-container'>
            <header className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
                <img src={companyLogo} alt="Logo de l'entreprise" className='navbar-logo' />
            </header>
            <div className='image-container'>
                <img src={primary_image} alt="Description de l'image" className='home-primary-image' />
                <div className='text-overlay'>
                    <h1>Votre réseau au bout des doigts</h1>
                    <p>Partagez vos informations facilement et instantanément</p>
                    <div className="button-container">
                        <a href="https://play.google.com/store/apps/details?id=com.mycompany.inzepocket" target="_blank" rel="noopener noreferrer">
                            <img src={googlePlayBadge} alt="Disponible sur Google Play" className="store-button" />
                        </a>
                        <a href="https://apps.apple.com/fr/app/in-ze-pocket/id6479369481" target="_blank" rel="noopener noreferrer">
                            <img src={appleStoreBadge} alt="Télécharger sur l'App Store" className="store-button" />
                        </a>
                    </div>
                    <div className="offer-button-container">
                        <a href="https://www.in-ze-pocket.shop/pages/produit" target="_blank" rel="noopener noreferrer" className="sales-button">
                            Commander votre carte
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;