export const handleSendEmail = async (email: string, message: string, subject: string): Promise<{ success: boolean }> => {
    if (!email) {
        return { success: false};
    }
    if (!message) {
        return { success: false };
    }
    if (!subject) {
        return { success: false };
    }

    const url = 'https://mekdumqqplwkwcxgajri.supabase.co/functions/v1/resend';

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`,
            },
            body: JSON.stringify({ email, message, subject }),
        });

        if (!response.ok) {
            return { success: false };
        }
        return { success: true };

    } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'email:', error);
        return { success: false };
    }
};
