import React from 'react';
import '../../styles/components/received_contact/TitleForm.css';

interface TitleProps {
    text: string;
}

const TitleForm: React.FC<TitleProps> = ({ text }) => {
    return <h2 className="title-form-style">{text}</h2>;
};

export default TitleForm;
