import React, { useEffect, useState } from 'react';

import '../../styles/components/custom_card_order/ColorSelector.css';

interface CustomCard {
    firstName: string;
    lastName: string;
    color: string;
    customColor: string;
}

interface ColorSelectorProps {
    customCard: CustomCard;
    onColorChange: (color: string, customColor?: string) => void;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({ customCard, onColorChange }) => {
    const [selectedColor, setSelectedColor] = useState(customCard.color);
    const [customColor, setCustomColor] = useState(customCard.customColor);

    useEffect(() => {
        setSelectedColor(customCard.color);
        setCustomColor(customCard.customColor);
    }, [customCard]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const color = event.target.value;
        setSelectedColor(color);
        if (color === 'custom') {
            onColorChange(color, customColor);
        } else {
            onColorChange(color);
        }
    };

    const handleCustomColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setCustomColor(value);
        onColorChange('custom', value);
    };

    const displayColor = selectedColor === 'custom' ? customColor : selectedColor === 'blanc' ? '#FFFFFF' : '#000000';

    return (
        <div className='container-color-selector'>
            <div className='header-color-selector'>
                <label htmlFor="color-select">Choisissez une couleur pour la carte :</label>
                <div
                    style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: displayColor,
                        display: 'inline-block',
                        border: '1px solid #000',
                    }}
                />
            </div>
            <div className='color-input-container'>
                <select id="color-select" value={selectedColor} onChange={handleChange} className='form-select-input'>
                    <option value="blanc">Blanc</option>
                    <option value="noir">Noir</option>
                    <option value="custom">Personnalisée</option>
                </select>
                {selectedColor === 'custom' && (
                    <input
                        type="text"
                        value={customColor}
                        onChange={handleCustomColorChange}
                        placeholder="Couleur sous format #RRGGBB"
                        title="Veuillez entrer une couleur sous format #RRGGBB"
                    />
                )}
            </div>
        </div>
    );
};

export default ColorSelector;
