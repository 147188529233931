import { supabase } from './supabaseClient';

const saveSharedContact = async (newContacts: any[], username: string) => {

    const formatDate = (date: Date): string => {
        return new Intl.DateTimeFormat('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date);
    };

    const contactsWithDate = newContacts.map(contact => ({
        ...contact,
        created_at: formatDate(new Date())
    }));

    const { data, error: fetchError } = await supabase
        .from('users')
        .select('shared_contacts, new_shared_contact')
        .eq('username', username)
        .single();

    if (fetchError) {
        throw new Error(`Erreur lors de la récupération des données existantes : ${fetchError.message}`);
    }

    const existingContacts = data?.shared_contacts || [];
    const updatedContacts = [...contactsWithDate, ...existingContacts];
    const newSharedCount = (data?.new_shared_contact || 0) + 1;

    const { error: updateError } = await supabase
        .from('users')
        .update({
            shared_contacts: updatedContacts,
            new_shared_contact: newSharedCount
        })
        .eq('username', username);

    if (updateError) {
        throw new Error(`Erreur lors de la mise à jour des données : ${updateError.message}`);
    }
};

export default saveSharedContact;
