import React from 'react';

import '../../styles/components/custom_card_order/ProgressBar.css';

interface ProgressBarProps {
    progressValue: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progressValue }) => {
    return (
        <div className="progress-bar-order">
            <div className="progress" style={{ width: `${progressValue}%` }}></div>
        </div>
    );
};

export default ProgressBar;
