import { supabase } from "./supabaseClient";
import { cardTypes } from "../types/cardOrders/cardTypes";
import { StatusCardOrders } from "../types/cardOrders/statusCardOrders";

interface CustomCard {
    firstName: string;
    lastName: string;
    color: string;
    customColor: string;
    imageUrl: string;
}

export const updateCustomCardOrder = async (customCards: CustomCard[], orderId: string | undefined) => {
    const OrderReference = `#${orderId}`;
    try {
        const { data: cardOrders, error } = await supabase
            .from('card_orders')
            .select('*')
            .eq('order_reference', OrderReference)
            .eq('card_type', cardTypes.PREMIUM)
            .eq('order_status', StatusCardOrders.MAIL_KIT_SENT)

        if (error) {
            throw new Error(`Erreur lors de la récupération des commandes de carte : ${error.message}`);
        }

        if (!cardOrders || cardOrders.length === 0) {
            throw new Error(`Aucune commande trouvée pour la référence de commande : ${orderId}`);
        }

        if (cardOrders.length !== customCards.length) {
            throw new Error(`Le nombre de cartes trouvées (${cardOrders.length}) ne correspond pas au nombre de cartes personnalisées (${customCards.length}).`);
        }

        for (let i = 0; i < customCards.length; i++) {
            const card = customCards[i];
            const cardOrder = cardOrders[i];

            const colorToUse = card.color === 'custom' ? card.customColor : card.color;

            const uniqueSuffix = Date.now();
            const imageFileName = `${orderId}_${cardOrder.id}_${uniqueSuffix}_card_image.png`;

            const blobResponse = await fetch(card.imageUrl);
            const blob = await blobResponse.blob();
            const file = new File([blob], imageFileName, { type: blob.type });

            const { data: uploadData, error: uploadError } = await supabase
                .storage
                .from('custom_cards')
                .upload(`cards/${imageFileName}`, file, {
                    cacheControl: '3600',
                    upsert: true
                });
            if (uploadError) {
                throw new Error(`Erreur lors du téléchargement de l'image : ${uploadError.message}`);
            }

            const { data } = supabase
                .storage
                .from('custom_cards')
                .getPublicUrl(`cards/${imageFileName}`);

            if (!data) {
                throw new Error(`Erreur lors de la récupération de l'URL de l'image.`);
            }

            const publicURL = data.publicUrl;

            const { error: updateError } = await supabase
                .from('card_orders')
                .update({
                    customer_first_name: card.firstName,
                    customer_last_name: card.lastName,
                    card_color: colorToUse,
                    card_picture: publicURL,
                    order_status: StatusCardOrders.MAIL_KIT_PENDING
                })
                .eq('id', cardOrder.id);

            if (updateError) {
                throw new Error(`Erreur lors de la mise à jour de la carte ID: ${cardOrder.id} - ${updateError.message}`);
            }
        }

    } catch (err: any) {
        console.error("Erreur lors de la mise à jour des commandes de cartes :", err.message);
        throw err;
    }
};

