import { createClient } from "@supabase/supabase-js";

if (!process.env.REACT_APP_SUPABASE_URL || !process.env.REACT_APP_SUPABASE_ANON_KEY) {
    throw new Error("Environment variables REACT_APP_SUPABASE_URL and REACT_APP_SUPABASE_ANON_KEY must be defined");
}

const supabaseUrl: string = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey: string = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
