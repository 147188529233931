import { handleSendEmail } from './cardOrderService';
import { batchUpdateCardOrderStatus } from './UpdateCardOrderStatus';

const ERROR_MESSAGES = {
    EMAIL_INVALID: 'Email non valide',
    HTML_CONTENT_INVALID: 'Contenu HTML non valide',
    SUBJECT_INVALID: 'Sujet non valide',
};

export const sendEmail = async (email: string, subject: string, htmlContent: string, updateStatus: string, orderReference: string) => {
    if (!email) {
        alert(ERROR_MESSAGES.EMAIL_INVALID);
        return;
    }
    if (!htmlContent) {
        alert(ERROR_MESSAGES.HTML_CONTENT_INVALID);
        return;
    }
    if (!subject) {
        alert(ERROR_MESSAGES.SUBJECT_INVALID);
        return;
    }

    const result = await handleSendEmail(email, htmlContent, subject);
    if (result.success) {
        await batchUpdateCardOrderStatus(updateStatus, orderReference);
        alert('Email envoyé avec succès et statut de la commande mis à jour');
    } else {
        alert('Erreur lors de l\'envoi de l\'email');
    }
};