import { supabase } from '../../../services/supabaseClient';
import { getToken } from '../../../hooks/fetchCardsByOrderReference';

export const exportCardsExcel = async (orderReference: string) => {
    const { data: cards, error } = await supabase
        .from('card_orders')
        .select('*')
        .eq('order_reference', orderReference);

    if (error) {
        console.error('Erreur lors de la récupération des cartes:', error);
        return [];
    }

    const jsonResult = await Promise.all(cards.map(async (card) => {
        const token = await getToken(card.card_id);
        return token ? {
            cardType: card.card_type,
            cardId: card.card_id,
            token,
            linkQRCode: 'https://in-ze-pocket.com/cards/' + card.card_id,
            customerEmail: card.customer_email,
            customerPhone: card.customer_phone,
            customerFirstName: card.customer_first_name,
            customerLastName: card.customer_last_name,
            cardPicture: card.card_picture,
            cardColor: card.card_color,
            orderReference,
        } : null;
    })).then(results => results.filter(card => card));

    const csvContent = convertToCSV(jsonResult);

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `cards_${orderReference}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return jsonResult;
};

const convertToCSV = (data: any[]) => {
    const headers = ['cardType', 'cardId', 'token', 'linkQRCode', 'customerEmail', 'customerPhone', 'customerFirstName', 'customerLastName', 'cardPicture', 'cardColor', 'orderReference'];

    const csvRows = [
        headers.join(','),
        ...data.map(card => headers.map(header => card[header]).join(','))
    ];

    return csvRows.join('\n');
};
