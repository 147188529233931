import React, { useState } from 'react';
import { ListCards } from '../../../types/cardOrders/listCards';
import '../../../styles/components/dashboard/CardOrderDashboard.css';
import OrderCard from './OrderCard';

interface CardOrderDashboardProps {
    listCards: ListCards[];
    fetchCardOrders: () => Promise<void>;
}

const CardOrderDashboard: React.FC<CardOrderDashboardProps> = ({ listCards }) => {
    const [expandedOrder, setExpandedOrder] = useState<string | null>(null);
    const [showCustomerInfo, setShowCustomerInfo] = useState<string | null>(null);

    const groupedOrders = listCards.reduce((acc, card) => {
        if (card.order_reference) {
            (acc[card.order_reference] = acc[card.order_reference] || []).push(card);
        }
        return acc;
    }, {} as Record<string, ListCards[]>);

    const handleToggle = (orderReference: string) => {
        setExpandedOrder(expandedOrder === orderReference ? null : orderReference);
    };

    return (
        <div className="body">
            <div className="body-center">
                <h1>Gestion des Commandes</h1>
                <div className="cards-container">
                    {Object.entries(groupedOrders).map(([orderReference, orders]) => (
                        <OrderCard 
                            key={orderReference} 
                            orderReference={orderReference} 
                            orders={orders} 
                            expandedOrder={expandedOrder} 
                            showCustomerInfo={showCustomerInfo} 
                            handleToggle={handleToggle} 
                            setShowCustomerInfo={setShowCustomerInfo} 
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CardOrderDashboard;
