import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Error from '../components/Error';
import LoadingScreen from '../components/LoadingScreen';
import OrderHeader from '../components/custom_card_order/OrderHeader';
import ProgressBar from '../components/custom_card_order/ProgressBar';
import CardNumberDisplay from '../components/custom_card_order/CardNumberDisplay';
import NavigationButtons from '../components/custom_card_order/NavigationButtons';
import ConfirmCardsButton from '../components/custom_card_order/ConfirmCardsButton';
import NameInputForm from '../components/custom_card_order/NameInputForm';
import ColorSelector from '../components/custom_card_order/ColorSelector';
import ImageUploader from '../components/custom_card_order/ImageUpload';

import { getCustomCardOrder } from '../services/getCustomCardOrder';
import { updateCustomCardOrder } from '../services/updateCustomCardOrder';
import { ListCards } from '../types/cardOrders/listCards';
import '../styles/pages/CustomCardOrder.css';
import '../styles/components/Error.css';
import logo from '../assets/images/logo_name.png';

interface CustomCard {
    firstName: string;
    lastName: string;
    color: string;
    customColor: string;
    imageUrl: string;
}

const CustomCardOrder: React.FC = () => {
    const { orderId, token } = useParams<{ orderId: string, token: string }>();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [orderData, setOrderData] = useState<ListCards[] | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [customCards, setCustomCards] = useState<CustomCard[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [loadingConfirmation, setLoadingConfirmation] = useState<boolean>(false);

    const progressValue = orderData && orderData.length > 0 ? (currentIndex / (orderData.length - 1)) * 100 : 0;

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (orderId && token) {
                    const orderReference = `#${orderId}`;
                    const data = await getCustomCardOrder(orderReference, token);
                    setOrderData(data);
                    if (data && data.length > 0) {
                        const initialCards = data.map(() => ({
                            firstName: '',
                            lastName: '',
                            color: 'blanc',
                            customColor: '',
                            imageUrl: ''
                        }));
                        setCustomCards(initialCards);
                    }

                } else {
                    setError("Ce lien de commande est incorrect.");
                }
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [orderId, token]);

    const handleNavigation = (direction: 'next' | 'prev') => {
        if (direction === 'next' && currentIndex < customCards.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
        if (direction === 'prev' && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleUpdateCustomCard = (updatedCard: CustomCard) => {
        const updatedCards = [...customCards];
        updatedCards[currentIndex] = updatedCard;
        setCustomCards(updatedCards);
    };

    const handleColorChange = (color: string, customColor?: string) => {
        const updatedCard: CustomCard = {
            ...customCards[currentIndex],
            color,
            customColor: customColor || '',
        };
        handleUpdateCustomCard(updatedCard);
    };

    const handleImageUpload = (imageUrl: string) => {
        setCustomCards((prevCards) => {
            const updatedCards = [...prevCards];
            updatedCards[currentIndex].imageUrl = imageUrl;
            return updatedCards;
        });
    };

    const handleConfirmCards = async () => {
        setErrorMessage(null);
        setLoadingConfirmation(true);

        for (const card of customCards) {
            if (!card.firstName || !card.lastName || !card.color || !card.imageUrl) {
                setErrorMessage("Veuillez remplir tous les champs pour chaque carte avant de confirmer.");
                setLoadingConfirmation(false);
                return;
            }

            if (card.color === 'custom' && !card.customColor.startsWith('#')) {
                setErrorMessage("Veuillez indiquer une couleur valide pour les cartes personnalisées (doit commencer par #).");
                setLoadingConfirmation(false);
                return;
            }
        }

        try {
            await updateCustomCardOrder(customCards, orderId);
            setIsConfirmed(true);
        } catch (error) {
            setErrorMessage("Une erreur est survenue lors du traitement de vos données. Veuillez réessayer plus tard.");
        } finally {
            setLoadingConfirmation(false); // Arrête le chargement
        }
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (isConfirmed) {
        return (
            <div className='error-container'>
                <img src={logo} alt="Logo" className="error-logo" />
                <span className="error-title">Merci !</span>
                <p className="error-message-found">Vos informations ont bien été prises en compte, vous recevrez très bientôt un mail pour la suite du traitement de votre commande."</p>
            </div>
        );
    }

    if (error) {
        return <Error error={error} />;
    }

    if (!orderData || orderData.length < 0) {
        return (
            <p>Aucune commande trouvée.</p>
        );
    }

    return (
        <div className='order-body'>
            <div className='container-order'>
                <OrderHeader orderId={`#${orderId}`} />
                <ProgressBar progressValue={progressValue} />
                <NameInputForm
                    customCard={customCards[currentIndex]}
                    onUpdate={handleUpdateCustomCard}
                />
                <ColorSelector
                    customCard={customCards[currentIndex]}
                    onColorChange={handleColorChange}
                />
                <ImageUploader
                    currentImage={customCards[currentIndex].imageUrl}
                    onImageUpload={handleImageUpload}
                />
                <NavigationButtons
                    currentIndex={currentIndex}
                    totalCards={customCards.length}
                    onNavigate={handleNavigation}
                />
                <CardNumberDisplay currentIndex={currentIndex} totalCards={customCards.length} />
                {currentIndex === customCards.length - 1 && (
                    <ConfirmCardsButton
                        onClick={handleConfirmCards}
                        errorMessage={errorMessage}
                        loading={loadingConfirmation}
                    />
                )}
            </div>
        </div>
    );
};

export default CustomCardOrder;
