import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../../styles/components/received_contact/PhoneInputForm.css';

interface PhoneInputFormProps {
    phone: string;
    setPhone: (value: string) => void;
    error: string;
}

const PhoneInputForm: React.FC<PhoneInputFormProps> = ({ phone, setPhone, error }) => {
    return (
        <div className="phone-input-form">
            <label htmlFor="phone" className="phone-input-label">
                Téléphone <span className="required">*</span>
            </label>
            <PhoneInput
                country={'fr'}
                value={phone}
                onChange={(value) => setPhone(value)}
                inputClass="phone-input-field"
                containerClass="phone-input-container"
            />
            {error && <span className="error-message-form">{error}</span>}
        </div>
    );
};

export default PhoneInputForm;
