import React from 'react';

import '../../styles/components/custom_card_order/ConfirmCardsButton.css';


interface ConfirmCardsButtonProps {
    onClick: () => void;
    errorMessage: string | null;
    loading: boolean;
}

const ConfirmCardsButton: React.FC<ConfirmCardsButtonProps> = ({ onClick, errorMessage, loading }) => {
    return (
        <div className='confirm-button-container'>
            <button onClick={onClick} className="confirm-cards-button" disabled={loading}>
                {loading ? 'Chargement...' : 'Confirmer les cartes'}
            </button>
            {errorMessage && (
                <p className={`error-message ${errorMessage ? 'visible' : ''}`}>
                    {errorMessage}
                </p>
            )}
        </div>
    );
};

export default ConfirmCardsButton;
