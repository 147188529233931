import React from 'react';

import '../../styles/components/custom_card_order/CardNumberDisplay.css';

interface CardNumberDisplayProps {
    currentIndex: number;
    totalCards: number;
}

const CardNumberDisplay: React.FC<CardNumberDisplayProps> = ({ currentIndex, totalCards }) => {
    return (
        <p className="card-number">
            Carte n°<span id="card-number">{currentIndex + 1}</span> sur <span id="total-cards">{totalCards}</span>
        </p>
    );
};

export default CardNumberDisplay;
