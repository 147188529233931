import { supabase } from '../services/supabaseClient';

export const fetchCardsByOrderReference = async (orderReference: string) => {
    const { data: cards, error } = await supabase
        .from('card_orders')
        .select('*')
        .eq('order_reference', orderReference);

    if (error) {
        console.error('Erreur lors de la récupération des cartes:', error);
        return [];
    }

    const jsonResult = await Promise.all(cards.map(async (card) => {
        const token = await getToken(card.card_id);
        return token ? {
            cardType: card.card_type,
            cardId: card.card_id,
            token,
            orderReference,
        } : null;
    })).then(results => results.filter(card => card));

    return jsonResult;
};

export const getToken = async (cardId: string) => {
    try {
        const { data, error } = await supabase
            .from('cards')
            .select('token')
            .eq('card_id', cardId)
            .single();
        if (error) {
            console.error(`Erreur lors de la récupération du token : ${error.message}`);
            return null;
        }
        return data?.token || null;
    } catch (error) {
        console.error('Erreur:', error);
        return null;
    }
};
