import React from 'react';
import Header from '../../components/dashboard/DashboardHeader';
import CardOrderDashboard from '../../components/dashboard/cardOrderDashboard/CardOrderDashboard';
import { User } from '../../types/user/user';
import { ListCards } from '../../types/cardOrders/listCards';

interface DashboardContentProps {
    user: User | null;
    loadingUser: boolean;
    errorUser: string | null;
    listCards: ListCards[] | null;
    loadingCards: boolean;
    errorCards: string | null;
    fetchCardOrders: () => Promise<void>;
    handleLogout: () => Promise<void>;
}

const DashboardContent: React.FC<DashboardContentProps> = ({
    user,
    loadingUser,
    errorUser,
    listCards,
    loadingCards,
    errorCards,
    fetchCardOrders,
    handleLogout,
}) => {
    return (
        <div>
            {user && <Header user={user} />}
            {loadingUser && <p>Chargement du profil...</p>}
            {loadingCards && <p>Chargement des commandes de cartes...</p>}
            {errorUser && <p>{errorUser}</p>}
            {errorCards && <p>{errorCards}</p>}
            {listCards && listCards.length > 0 && (
                <CardOrderDashboard listCards={listCards} fetchCardOrders={fetchCardOrders} />
            )}
            <button onClick={handleLogout}>Se déconnecter</button>
        </div>
    );
};

export default DashboardContent;
