import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'
import Profile from './pages/Profile';
import Dashboard from './pages/adminpanel/Dashboard';
import Cards from './pages/Cards';
import CustomCardOrder from './pages/CustomCardOrder';

import './styles/global.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile/:username" element={<Profile />} />
        <Route path="/cards/:id" element={<Cards />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/order/custom-card/:orderId/:token" element={<CustomCardOrder  />}/>
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
