import React from "react";
import { SocialLinks } from "../types/user/socialLinks";

import "../styles/components/CustomLinks.css";

interface CustomLinksProps {
    customLinks: SocialLinks["customLinks"] | null | undefined;
}

const CustomLinks: React.FC<CustomLinksProps> = ({ customLinks }) => {

    if (!customLinks) return null;

    const { link1, link2, link3, title1, title2, title3 } = customLinks;

    return (
        <div className="custom-links-container">
            {title1 && link1 && (
                <a href={link1} target="_blank" rel="noopener noreferrer" className="custom-button">
                    {title1}
                </a>
            )}
            {title2 && link2 && (
                <a href={link2} target="_blank" rel="noopener noreferrer" className="custom-button">
                    {title2}
                </a>
            )}
            {title3 && link3 && (
                <a href={link3} target="_blank" rel="noopener noreferrer" className="custom-button">
                    {title3}
                </a>
            )}
        </div>
    );
};

export default CustomLinks;
