import React from 'react';
import { IoIosSend } from "react-icons/io";
import '../../styles/components/received_contact/FloatingButton.css';

interface FloatingButtonProps {
    onClick: () => void;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ onClick }) => (
    <button className="floating-button" onClick={onClick}>
        <IoIosSend className="icon-send" />
    </button>
);

export default FloatingButton;
