import React from 'react';
import '../../styles/components/received_contact/EmailInputForm.css';

interface EmailInputFormProps {
    email: string;
    setEmail: (value: string) => void;
    error: string;
}

const EmailInputForm: React.FC<EmailInputFormProps> = ({ email, setEmail, error }) => {
    return (
        <div className="email-input-form">
            <label htmlFor="email" className="email-input-label">
                Email <span className="required">*</span>
            </label>
            <input
                type="email"
                id="email"
                name="email"
                placeholder="Adresse email"
                className="email-input-field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            {error && <span className="error-message-form">{error}</span>}
        </div>
    );
};

export default EmailInputForm;
