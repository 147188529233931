import React from 'react';
import { User } from '../../types/user/user';
import '../../styles/components/dashboard/HeaderDashboard.css';
import CardAddCustomer from '../../components/dashboard/CardAddCustomer';

const DEFAULT_PROFILE_URL = "https://i.pinimg.com/564x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg";

interface DashboardHeaderProps {
  user: User;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ user }) => {
  return (
    <header className="header">
      <CardAddCustomer />
      <HeaderTitle title="Dashboard" />
      <UserProfile user={user} />
    </header>
  );
};

const HeaderTitle: React.FC<{ title: string }> = ({ title }) => (
  <div className="header-center">
    <h1>{title}</h1>
  </div>
);

const UserProfile: React.FC<{ user: User }> = ({ user }) => (
  <div className="header-right">
    <p>{user.first_name} {user.last_name}</p>
    <img
      src={user.profilePictureUrl || DEFAULT_PROFILE_URL}
      alt={`${user.first_name} ${user.last_name} Avatar`}
      className="header-right-avatar"
    />
  </div>
);

export default DashboardHeader;
